import React from "react";

export default ({ color }) => (
  <svg width="91" height="106" viewBox="0 0 91 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.9276 63.0867C51.1823 62.5479 55.818 57.0154 55.2818 50.7297C54.7456 44.444 49.2405 39.7852 42.9858 40.3241C36.7311 40.863 32.0953 46.3954 32.6315 52.6811C33.1678 58.9669 38.6729 63.6256 44.9276 63.0867Z" fill="#333333" />
    <path d="M53.2946 57.2114C58.412 56.7705 62.2049 52.244 61.7662 47.1011C61.3275 41.9582 56.8233 38.1465 51.7058 38.5874C46.5884 39.0283 42.7955 43.5549 43.2342 48.6977C43.6729 53.8406 48.1771 57.6523 53.2946 57.2114Z" fill="#FFB000" />
    <path d="M87.5705 22.3316L50.1582 0.731635C47.5601 -0.768365 44.2816 0.110096 42.7816 2.70817L0.731635 75.5409C-0.768365 78.139 0.110095 81.4174 2.70817 82.9174L40.1205 104.517C42.7185 106.017 45.997 105.139 47.497 102.541L89.547 29.7082C91.047 27.1101 90.1685 23.8316 87.5705 22.3316ZM60.6584 13.1448L71.3971 19.3448C72.2631 19.8448 72.5559 20.9376 72.0559 21.8036C71.5559 22.6696 70.4631 22.9625 69.5971 22.4625L58.8584 16.2625C57.9923 15.7625 57.6995 14.6696 58.1995 13.8036C58.6995 12.9376 59.7923 12.6448 60.6584 13.1448ZM24.3143 88.6945C22.1493 87.4445 21.3306 84.6624 22.5806 82.4974C23.8306 80.3323 26.5627 79.6003 28.8143 80.9003C30.9794 82.1503 31.7114 84.8823 30.4614 87.0474C29.2114 89.2124 26.4794 89.9445 24.3143 88.6945ZM49.0848 85.7907L14.4438 65.7907L44.2938 14.089L78.9348 34.089L64.0098 59.9399L49.0848 85.7907Z" fill="black" />
  </svg>


);
